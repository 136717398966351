<template>
    <span class="number-grow-warp">
        <span ref="numberGrow" :data-time="time" class="number-grow" :data-value="value">0</span>
    </span>
</template>

<script>
import util from '@/utils/common-util'
export default {
  name:'numberGanttyNum',
  props: {
    time: {
      type: Number,
      default: 2
    },
    value: {
      type: Number,
      default: 0
    },
    type:{
      type: Number,
      default: 0
    },
    thousandSign:{
        type: Boolean,
        default:()=>false
    }
  },
  data(){
    return{
      oldValue:0,
      times: " ",
      t: ''
    }
  },
  watch:{
    value(n, o){
      this.numberGrow(this.$refs.numberGrow)
    }
  },
  methods: {
    numberGrow (ele) {
      let _this = this
      let value;
      if(this.value > this.oldValue){
        value = this.value - this.oldValue
      }else{
        value = this.value;
        this.oldValue = 0
      }
      let step = (value * 10) / (this.time * 100)
      let current = 0
      let start = this.oldValue
      this.times = setInterval(function () {
        start += step
        if (start > _this.value) {
          clearInterval(this.times)
          start = _this.value
          _this.times = null
        }
        if (current === start) {
          return
        }
        current = start
        _this.oldValue = current
        ele.innerHTML = util.numFormat(current,_this.type,'floor');
      }, 10)
    }
  },
  beforeUnmount(){
    clearInterval(this.times)
  }
}
</script>

<style scoped>
.number-grow-warp{
    transform: translateZ(0);
}
</style>
